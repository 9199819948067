import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Img from "gatsby-image";
import { Row, Col } from "glamorous-grid";
import Slider from "react-slick";
import { GoQuote } from "react-icons/go";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = g.section({
  backgroundColor: `white`,
  marginBottom: `.5em`,
  borderRadius: `6px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  padding: `1.25em 1em 1.5em 1.5em`,
  "@media(max-width: 767px)": {
    padding: `.8em`,
  },
});

const H3 = g.h3({
  position: `relative`,
  fontSize: `28px`,
  fontWeight: `500`,
  marginTop: `0.4em`,
  marginBottom: `1.2em`,
  "&::after": {
    content: ` `,
    position: `absolute`,
    top: `54%`,
    left: `185px`,
    border: `.063em solid #3c4d68`,
    display: `inline-block`,
    width: `318px`,
  },
  "@media(max-width: 767px)": {
    margin: `.5em 0 1.2em`,
    "&::after": {
      content: `none`,
    },
  },
  "@media(max-width: 1100px)": {
    "&::after": {
      content: `none`,
    },
  },
});

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const cssButton = css({
  color: `white`,
  backgroundColor: `#3c4d68`,
  border: `1px solid #3c4d68`,
  padding: `.4em 1.5em`,
  display: `block`,
  margin: `2em 0 10px`,
  borderRadius: `5px`,
  textAlign: `center`,
  fontWeight: `500`,
  fontSize: `15px`,
  width: `165px`,
  float: `right`,
  textDecoration: `none`,
  letterSpacing: `.03em`,
  transition: `all 0.3s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    color: `#3c4d68`,
    transition: `all 0.3s ease-in-out 0s`,
  },
});

const cssQuote = css({
  fontSize: `54px`,
  color: `#3c4d68`,
  display: `block`,
  marginLeft: `-.2em`,
  marginTop: `.25em`,
});

const P = g.div({
  width: `65%`,
  margin: `0 auto`,
  minHeight: `230px`,
  lineHeight: `25px`,
  "@media(max-width: 767px)": {
    width: `87%`,
  },
});

const Hr = g.hr({
  color: `#3c4d68`,
  backgroundColor: `#3c4d68`,
  height: `1px`,
  margin: `.8em 0 0`,
});

const Legende = g.div({
  color: `#3c4d68`,
  textAlign: `right`,
  letterSpacing: `.04em`,
  fontWeight: `600`,
  fontSize: `13px`,
  marginRight: `.2em`,
  marginBottom: `1.5em`,
});

css.global(".slick-prev:before", {
  color: `#3c4d68 !important`,
  opacity: `1 !important`,
  fontSize: `30px !important`,
});

css.global(".slick-next:before", {
  color: `#3c4d68 !important`,
  opacity: `1 !important`,
  fontSize: `30px !important`,
});

css.global(".slick-prev", {
  top: `0% !important`,
  left: `43% !important`,
  width: `30px !important`,
  height: `36px !important`,
  "@media(max-width: 767px)": {
    left: `40% !important`,
  },
});

css.global(".slick-next", {
  top: `0% !important`,
  right: `43% !important`,
  width: `30px !important`,
  height: `36px !important`,
  "@media(max-width: 767px)": {
    right: `40% !important`,
  },
});

css.insert(
  "@media(max-width: 767px) {.slick-prev {left: 40% !important;}.slick-next {right: 40% !important;}}"
);

export default () => (
  <StaticQuery
    query={graphql`
      query avantaprestemoignages {
        file(
          relativePath: {
            eq: "images/avant-apres/avant-apres-rhinoplastie-jeune-fille-face.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 530) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper>
        <Row>
          <Col span={{ xs: 12 / 12, lg: 50 / 100 }}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              css={{ marginTop: `.6em`, borderRadius: `4px` }}
              alt="Photographie avant - après d'une rhinoplastie - opération de chirurgie esthétique du nez"
            />
            <Link {...cssButton} to="/avant-apres/">
              Voir les Avant-Après
            </Link>
          </Col>

          <Col span={{ xs: 12 / 12, lg: 50 / 100 }}>
            <Link to="/temoignages-avis-patients/">
              <H3>Témoignages</H3>
            </Link>
            <Slider {...settings} css={{ outline: `none` }}>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Un chirurgien esthétique à l'écoute et qui conseil en fonction
                  de la morphologie de sa patiente. Son but est d'avoir des
                  résultats naturels et c'est ce que j'ai eu la chance d'avoir.
                  Une poitrine parfaite&nbsp;! Belle et très naturelle&nbsp;! Et
                  en plus de ça je n'ai eu aucune douleur après
                  l'opération&nbsp;!
                  <Hr />
                  <Legende>Augmentation mammaire</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Le résultat est naturel, mon visage a retrouvé sa jeunesse et
                  les cicatrices, extrêmement fines, ont pratiquement disparues
                  à peine 15 jours après l'opération. Un grand merci au Dr
                  Cédric Kron pour son travail remarquable et sa gentillesse.
                  <Hr />
                  <Legende>Augmentation mammaire</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  J'ai eu la chance de bénéficier de l'expertise du Dr Kron pour
                  un lifting complet du visage. Le résultat est
                  stupéfiant&nbsp;: présentable au bout de 3&nbsp;jours,
                  cicatrices cachées et invisibles un mois plus tard.
                  <Hr />
                  <Legende>Lifting du visage</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Ce que vous avez fait me plaît vraiment énormément, c'est très
                  très beau. Je suis très heureuse et émerveillée à la fois car
                  le résultat est bien au-delà de mes attentes, je n'imaginais
                  pas à quel point ça serait joli.
                  <Hr />
                  <Legende>Chirurgie des seins</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Dès les premiers instants, j'ai compris que je me trouvais en
                  face d'un médecin qui non seulement est un grand
                  professionnel, mais aussi un médecin sensible, délicat et
                  intelligent, qui ne juge pas et n'impose pas ses vues.
                  <Hr />
                  <Legende>Prothèses mammaires</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Le Dr Kron, outre ses compétences techniques de haut vol,
                  porte un vrai regard sur ses patients. C'est sa vision
                  quasi-artistique du corps qui m'a poussée à passer entre ses
                  mains. J'ai retrouvé la poitrine de mes 20&nbsp;ans...
                  <Hr />
                  <Legende>Augmentation mammaire</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Je me suis faite opérée il y a maintenant 6&nbsp;mois et
                  resultat encore meilleur que dans mes attentes. Chirurgien a
                  l'écoute, perfectionniste et tres professionnel. Je le
                  recommande c'est vraiment le meilleur. (Avis publié sur Google
                  Maps)
                  <Hr />
                  <Legende>Rhinoplastie</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Tellement heureuse d’avoir choisi le Dr Kron... Il a changé ma
                  vie, je me sens vraiment bien à présent, confiante. Il a
                  vraiment été à l’écoute, bienveillant, professionnel et
                  surtout talentueux. (Avis publié sur Google Maps)
                  <Hr />
                  <Legende>Chirurgie des seins</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Je tiens à remercier sincèrement le Dr Kron pour la
                  «&nbsp;magique&nbsp;» reconstruction mammaire qu'il a effectué
                  suite à la mastectomie que j'avais subi pour ma guérison.
                  <Hr />
                  <Legende>Reconstruction mammaire</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Aujourd'hui cela fait 4 mois et je peux vous dire que suis une
                  autre. J'ai repris confiance en moi et suis très heureuse de
                  ma nouvelle vie (physique).
                  <Hr />
                  <Legende>Abdominoplastie / Augmentation mammaire</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  Je recommande ce chirurgien esthétique. Il est top et si je
                  dois faire une autre intervention, ce sera avec lui.
                  <Hr />
                  <Legende>Lifting du visage LVPA</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  J'ai fait plusieurs séances de Laser et je suis ravie du
                  résultat esthétique, ma peau est devenu plus lumineuse,
                  repulpée et mes tâches ont quasiment disparu.
                  <Hr />
                  <Legende>Laser Icon</Legende>
                </P>
              </div>
            </Slider>
          </Col>
        </Row>
      </Wrapper>
    )}
  />
);
