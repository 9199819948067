import React from "react";
import g from "glamorous";
import Link from "gatsby-link";
import { FaPlus } from "react-icons/fa";

const Div = g.div({
  borderRadius: `0 0 4px 4px`,
  padding: `12px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `0 0 2em`,
  transition: `all 0.15s ease-in-out`,
});

const Title = g.div({
  margin: `0 auto .8em`,
  letterSpacing: `0.05em`,
  fontSize: `1.2em`,
  paddingBottom: `3px`,
  color: `#49697e`,
  borderBottom: `2px solid #49697e`,
  fontWeight: `bold`,
});

const Date = g.div({
  fontSize: `.7em`,
  letterSpacing: `0.05em`,
  fontWeight: `600`,
  color: `#49697e`,
  marginBottom: `-3px`,
});

const NewsTitle = g.div({
  fontSize: `.95em`,
  lineHeight: `1.4em`,
  margin: `0 0 1em`,
  color: `#1f1f1f`,
  borderBotton: `none`,
  paddingBottom: `2px`,
  ":hover": {
    textDecoration: `underline`,
  },
});

const More = g.div({
  color: `#49697e`,
  fontSize: `14px`,
  textAlign: `right`,
});

export default ({ ...props }) => (
  <Div>
    <Title>Dernières actualités</Title>

    <Date>20/03/2024</Date>
    <Link to="/visage/lifting-du-cou-platysmaplastie/">
      <NewsTitle>
      Lifting du cou par platysmaplastie cutanéo-musculaire
      </NewsTitle>
    </Link>

    <Date>25/01/2023</Date>
    <Link to="/actualites/sisthaema/">
      <NewsTitle>
        Sisthaema&nbsp;: un «&nbsp;Skinbooster&nbsp;» de dernière génération,
        pour une régénération cutanée systématique et plus durable
      </NewsTitle>
    </Link>

    <Date>06/01/2023</Date>
    <Link to="/regard/plexr/">
      <NewsTitle>
        Plexr® : Traitement des paupières, des rides et des imperfections
        cutanées
      </NewsTitle>
    </Link>

    <Date>05/10/2022</Date>
    <Link to="/chirurgie-esthetique-sous-anesthesie-locale/">
      <NewsTitle>
        Chirurgie esthétique sous anesthésie locale : mini-lift, augmentation
        mammaire, liposuccion douce
      </NewsTitle>
    </Link>

    <Date>27/06/2022</Date>
    <Link to="/visage/ellanse/">
      <NewsTitle>
        ELLANSÉ® : Prévenir et corriger les signes du vieillissement du visage
      </NewsTitle>
    </Link>

    <Date>10/06/2022</Date>
    <Link to="/actualites/implants-ergonomix2/">
      <NewsTitle>
        Implants Ergonomix2® : Un résultat toujours plus naturel avec une
        sécurité accrue
      </NewsTitle>
    </Link>

 

    <More>
      <Link to="/actualites/" css={{ color: `#49697e` }}>
        <FaPlus
          css={{
            fontSize: `1em`,
            padding: `0 .3em 0.13em`,
            transform: `translateY(3px)`,
          }}
        />
        Toutes les actualités
      </Link>
    </More>
  </Div>
);
